import React, { useState, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import FrontServiciosAPI from '../servicios/FrontServicios';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import Constantes from '../comun/Constantes';

function Registro ({pagina, materia}){
    const [guardando, setGuardando] = useState(false);
    const mensaje = useRef(null);

    const datosMateria = JSON.parse(localStorage.getItem(Constantes.LSDATOSMATERIA+materia.id));

    const _guardar = async () => {
        let form = document.querySelector('#formulario');
        let data = new FormData(form);
        data = serialize(data);
        console.log('data',data)
        if(typeof data.usuario === 'undefined'){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: 'Falta ingresar el usuario'
            });
        }else{
            setGuardando(true);
            const response = await FrontServiciosAPI.postFront('registrar',data);
            if(!response.ok){
                const errormsj = await response.json();
                mensaje.current.show({
                    severity: 'warn',
                    sticky: true,
                    detail: errormsj
                });
            }else{
                mensaje.current.show({
                    severity: 'info',
                    sticky: true,
                    detail: 'Hemos registrado tus datos con éxito'
                });
            }
            setGuardando(false);
        }
        
    }

    const CargarPagina = () => {
        return ReactHtmlParser(pagina, {
            transform: (node) => {
                if (node.type === 'tag') {
                    if(node.name === 'button'){
                        return <div key="bt1"><p className="lead">{datosMateria && ReactHtmlParser(datosMateria.textoBasicoRegistro)}</p>
                        <Button type="button" loading={guardando} className={node.attribs.class} label={node.children[0].data} onClick={() =>_guardar()} />
                        </div>;
                    }/*else if(node.name === 'input' && node.attribs.type==='file'){
                        return (<React.Fragment key="imgf1">
                        <FileUpload key="img1" ref={inputFile} mode="basic" name={node.attribs.name} customUpload onSelect={(e)=>onSelect(e,node.attribs.name)} uploadHandler={() => {return;}}  accept="*.jpg" maxFileSize="1048576" invalidFileSizeMessageDetail="Por favor ingrese un archivo de hasta 1Mb" chooseLabel="Seleccionar archivo"></FileUpload>
                        <div key="nombreimg" className="nombreimagen">{nombreImagen}</div>
                        </React.Fragment>)
                    }*/   
                }
            } 
        });
    }

    return <div><Messages ref={mensaje} />
        <CargarPagina key="1" />
        
    </div>;
}

function serialize (data) {
	let obj = {};
	for (let [key, value] of data) {
        //console.log('ser',key,value)
		if (obj[key] !== undefined) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
            if(key.includes("adicional")){
                if(typeof obj.perfil=== 'undefined'){
                    obj['perfil']=[];
                }
                const idadicional = key.split("_");
                obj['perfil'].push({'id':idadicional[1],'valor':value});
            }else{
                obj[key] = value;
            }
            //console.log('obj',key,value)
			
		}
	}
	return obj;
}

export default Registro;