import React, { useState, useEffect, useRef }  from 'react';
import './login.css';  
import ReactHtmlParser from 'react-html-parser';
import { useCookies } from 'react-cookie';
import FrontServiciosAPI from '../servicios/FrontServicios';
import { Config } from '../comun';
import { Toast } from 'primereact/toast';
import Constantes from '../comun/Constantes';
import CuerpoCarga from '../comun/CuerpoCarga';
import Entrar from './Entrar';

function Inicio (){
    const [pagina, setPagina] = useState(null);
    const [materia, setMateria] = useState(null);
    const [, setCookie] = useCookies(['user']);
    const [cargando, setCargando] = useState(false);
    const toast = useRef(null);

    async function cargarPaginaInicio() {
        setCargando(true);
        const response = await FrontServiciosAPI.getFront('paginabasica');
        if(response.ok){
            const html = await response.json();
            document.title=html.materia.nombre;
            document.getElementById('favicon').href=`${Config.cloudFront}${html.materia.id}/recursos/favicon.png`;
            if(html.pagina!==''){
                let strpagina = html.pagina.replaceAll('<link href=\"','<link href=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
                strpagina = strpagina.replaceAll('rel="stylesheet" href=\"','rel="stylesheet" href=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
                strpagina = strpagina.replaceAll('src=\"','src=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
                var parser = new DOMParser();
                var doc = parser.parseFromString(strpagina, 'text/html');
                for (let i = 0; i < doc.head.childNodes.length; i++) {
                    if(doc.head.childNodes[i].tagName==='LINK' || doc.head.childNodes[i].tagName==='SCRIPT' || doc.head.childNodes[i].tagName==='STYLE'){
                        document.head.appendChild(doc.head.childNodes[i]);
                    }
                }
                setPagina(doc.body.innerHTML);
            }else{
                setPagina('');
            }
            
            //setPagina(strpagina);
            setMateria(JSON.parse(JSON.stringify(html.materia)));
            localStorage.setItem(Constantes.LSDATOSMATERIA+html.materia.id, JSON.stringify(html.materia.datos));
            delete html.materia['datos'];
            setCookie(Constantes.NOMBRECKMATERIA, html.materia, { path: '/' });
        }else{
            const error = await response.json();
            console.log('error',error);
        }
        setCargando(false);
    };

    useEffect( () => {
        document.body.classList.add("login-page");
		cargarPaginaInicio();
	}, []);

    return <div><Toast ref={toast} />
        <CuerpoCarga cargando={cargando}>{materia?<div className="container pagina-inicio">
            <div className="row d-flex align-items-center">
                    <div className="col-md-8">
                        <h1 className="titulo-curso">{materia.nombre}</h1>
                        <p className="lead">{materia.datos && ReactHtmlParser(materia.datos.textoBasicoInicio)}</p>
                    </div>
                    <div className="col-md-4">
                        <Entrar pagina={pagina} materia={materia}/>
                    </div>
                </div>
            </div>:null}
        </CuerpoCarga>
        </div>;
}

export default Inicio;