import React, { useState, useRef } from 'react';
import FrontServiciosAPI from '../servicios/FrontServicios';
import md5 from 'crypto-js/md5'; 
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useCookies } from 'react-cookie';
import { Messages } from 'primereact/messages';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import Constantes from '../comun/Constantes';
import Registro from './Registro';

function Entrar({pagina, materia}) {  
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [, setCookie] = useCookies(['user']);
    const [cargando, setCargando] = useState(false);
    const [verRegistro, setVerRegistro] = useState(false);
    const mensaje = useRef(null);

    
    const _entrar = async () => {
        if(document.getElementById('acuerdo') && !document.getElementById('acuerdo').checked){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: 'Por favor marque la casilla de acuerdo'
            });
            return;
        }
        setCargando(true);
        const response = await FrontServiciosAPI.postFront('entrar',{usuario: usuario, clave: md5(clave).toString()});
        if(!response.ok){
            const errormsj = await response.json();
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: errormsj
            });
        }else{
            let datosUsuario = await response.json();
            //console.log('usuario',datosUsuario.estudiante);
            if(datosUsuario.estudiante.datos !== null){
                datosUsuario.estudiante.datos = JSON.parse(datosUsuario.estudiante.datos);
            }
            setCookie(Constantes.NOMBRECKUSUARIO, datosUsuario.estudiante, { path: '/' });
            setCookie(Constantes.NOMBRECKCOOKIE, datosUsuario.cookie, { path: '/' });
            setCookie(Constantes.NOMBRECKOTRASMATERIAS, datosUsuario.materias, { path: '/' });
            
            window.location.reload(false);
        }
        setCargando(false);
    }
    
    const _registrar = () => {
        setVerRegistro(true);
    }

    return (
        <Card className='cuadro-login'>
            <Messages ref={mensaje} />
                <div className="form-group">
                    <label>Usuario</label>
                    <InputText className="form-control p-inputtext" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Clave</label>
                    <InputText className="form-control p-inputtext" type="password" value={clave} onChange={(e) => setClave(e.target.value)} />
                </div>
                <div className="form-group text-center">
                    <Button type="button" loading={cargando} className="btn btn-primary w-100 mt-3" label='Entrar' onClick={() =>_entrar(usuario,clave)} />
                </div>
                {pagina!=='' && <><Divider align="center">
                <span>¿Eres nuevo?</span>
                </Divider>
                <div className="form-group text-center">
                    <Button type="button" className="btn btn-success w-100 mt-3" label='Regístrate' onClick={() =>_registrar()} />
                    <Dialog header='Regístrate' style={{ width: '50vw' }} position="top" visible={verRegistro} onHide={() => setVerRegistro(false)}>
                        <Registro pagina={pagina} materia={materia} />
                    </Dialog>
                </div></>}
        </Card>
    )
}

export default Entrar